import React, { useContext, useEffect, useState } from 'react';
import { Container, Tabs, Tab, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, DialogContentText, InputAdornment } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSpacingParams } from '@mui/x-data-grid';
import { IconButton,Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import settingsiconAnnouncements from "../../assets/icons/SettingsiconAnnouncements.svg";
import { useSnackbar } from '../../SnackbarContext';
import CreateNewPopup from '../common/CreateNewGuidePopup';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../account/AccountContext';
import CloneInteractionDialog from '../common/CloneGuidePopup';
import { getAllGuides,DeleteGuideByGuideId,GetGudeDetailsByGuideId } from '../../services/GuideService';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../common/TimeZoneConversion';
import { Fileedit, clone, Delete } from "../../assets/icons/icons";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NoDataw from "../../assets/icons/NoDataw.svg";

interface Hotspot {
  AccountId: string;
  Content: string;
  CreatedBy: string;
  CreatedDate: string;
  Frequency: string;
  GuideId: string;
  GuideStatus: string;
  GuideType: string;
  Name: string;
  OrganizationId: string;
  Segment: string;
  TargetUrl: string;
  TemplateId: string;
  UpdatedBy: string;
  UpdatedDate: string;
}

let statusss: any;
const Hotspots = () => {
  const { t: translate } = useTranslation();
  const [activeTab, setActiveTab] = useState(0); 
  const[name,setName]=useState("Hotspot")
  const [Hotspots, setHotspots] = useState<Hotspot[]>([]);
  const [HotspotsNew, setHotspotsNew] = useState<Hotspot[]>([]);
  const [orderByFields, setOrderByFields] = useState("");
  const [filters, setFilters] = useState([]);
  const [filterss, setFilterss] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const { openSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [guideIdToDelete, setGuideIdToDelete] = useState<string | null>(null);
  const [GuidenametoDelete, setGuideNametoDelete] = useState('');
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);
	const [cloneHotspotName, setcloneHotspotName] = useState<Hotspot | null>(null);
  const [loading, setLoading] = useState(false);
  const userInfo = localStorage.getItem('userInfo');
  const parsedInfo = JSON.parse(userInfo ? userInfo : '{}');
  const userData = JSON.parse(parsedInfo.user ? parsedInfo.user:'{}');
  const organizationId = userData?.OrganizationId;
  const [skip, setskip] = useState(0);
  const [top, settop] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const { accountId } = useContext(AccountContext);
  const navigate = useNavigate();
	const openPopup = () => {
    setShowPopup(true);
    
  };


  const fetchHotspots = async () => {
    const { page, pageSize } = paginationModel;
    const offset = page * pageSize;
    const statusFilter = activeTab === 0 ? 'Active' : activeTab === 1 ? 'InActive' : 'Draft';
    setHotspots([]);
    const filters = [
      {
        FieldName: 'GuideType',
        ElementType: 'string',
        Condition: 'equals',
        Value: 'Hotspot',
        IsCustomField: false,
      },
      {
        FieldName: 'GuideStatus',
        ElementType: 'string',
        Condition: 'equals',
        Value: statusFilter,
        IsCustomField: false,
      },
      {
        FieldName: 'Name',
        ElementType: 'string',
        Condition: 'contains',
        Value: searchQuery,
        IsCustomField: false,
      },
      {
        FieldName: 'AccountId', 
        ElementType: 'string',
        Condition: 'contains',
        Value: accountId,
        IsCustomField: false,
        },
    ];
    

    const orderByFields = '';
    const data = await getAllGuides(offset, pageSize, filters, orderByFields);
    if (data._count > 0)
    {
      const rowsWithIds = data.results.map((item: any) => ({
        ...item,
        id: item.GuideId
      }));  
      setHotspots(rowsWithIds);
      setTotalCount(data._count);      
    }
    else {
      setHotspots([]);
      setTotalCount(0); 
    }
   
  };


  useEffect(() => {
    if (accountId) {
      fetchHotspots();
    }
  }, [paginationModel, activeTab, accountId]);

  const handleSearch = () => {
    fetchHotspots(); 
    };
    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSearch(); 
      }
      };
  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
    statusss = newValue;
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  };
  const handleSettingsClick = async (params: any) => {
    const guideId = params.GuideId;
    const response = await GetGudeDetailsByGuideId(guideId);
    if (response) {  
      navigate(`${guideId}/settings`, { state: { response } });
    } else {
      openSnackbar("GuideId Was empty", "error");
    }
  };
  const changeTabToDraft = () => {
    setActiveTab(3);
  };
  const handleDelete = async () => {
    if (guideIdToDelete) {
      try {
        const response = await DeleteGuideByGuideId(guideIdToDelete);
        if (response.Success) {
          openSnackbar(`${GuidenametoDelete} deleted Successfully`, "success");
          await fetchHotspots();  
        } else {
          openSnackbar(response.ErrorMessage, "error");
        }
      } catch (error) {
      
      }
    }
    setOpenDialog(false);
    setGuideIdToDelete(null);
    setGuideNametoDelete("");
  };
  const handleDeleteConfirmation = (guideId: string) => {
    setGuideIdToDelete(guideId);
    setOpenDialog(true);
  };
  const handleCopyClick = (guide: Hotspot) => {
    setcloneHotspotName(guide);
    setIsCloneDialogOpen(true);
  };
  const handleEditClick = (guide: Hotspot) => {
    const targetUrl = guide?.TargetUrl; 
    if (targetUrl) {
      window.open(targetUrl, '_blank');
    } 
   
  };
  const handleCloneSuccess = async () => {
    await fetchHotspots();
  };
  const handleSearchQueryChange = (event:any) => {
    const value = event.target.value;
    setSearchQuery(value);
 
    if (value === '') {
      fetchHotspots(); 
    }
  };
  
  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Name',
      hideable: false,
    },
    {
      field: 'UpdatedDate',
      headerName: 'Last Edited',
      hideable: false,
      renderCell: (params) => (
        <span>
        {`${formatDateTime(params.row.UpdatedDate, 'dd-MM-yyyy')}`}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      hideable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Tooltip arrow title="Edit">
            <IconButton onClick={() =>handleEditClick(params.row)}>
            <img src={Fileedit} alt="Edit"/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Clone">
            <IconButton onClick={() => handleCopyClick(params.row)}>
            <img src={clone} alt="Clone"/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Settings">
		        <IconButton onClick={() => handleSettingsClick(params.row)}>
		  <SettingsOutlinedIcon sx={{color:"#000"}} />

            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Delete">
            <IconButton onClick={() => {
              handleDeleteConfirmation(params.row.GuideId);
              setGuideNametoDelete(params.row.Name);
            }}>
            	<img src={Delete} alt="Delete" style={{ filter: "brightness(0.5)" }} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);
  const getNoRowsLabel = () => {
		const tabLabels = ["Active", "Inactive", "Draft"];
		const currentTabLabel = tabLabels[activeTab] || name;
		return `No ${currentTabLabel} ${name}s`;
	};
	const NoRowsOverlay = () => (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection:"column" }}>
         <img
											src={NoDataw}
											alt="nodataw"
										/>
			<Typography sx={{fontWeight:"600"}}>{getNoRowsLabel()}</Typography>

        </div>
      );
  return (
    <Container maxWidth="xl"
  >
    <div className='qadpt-web'>
      <div className='qadpt-webcontent'>
    <div className="qadpt-head">
            <div className="qadpt-title-sec">
            <div className="qadpt-title">{translate('Hotspots')}</div>
            </div>
            <div className="qadpt-right-part">
              <button
                onClick={openPopup}
                className="qadpt-memberButton"
              >
                <i className="fal fa-add-plus"></i>
                <span>{translate('New Hotspot')}</span>
              </button>
            </div>
        </div>
        <div className="qadpt-tabs-container">
          <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label={translate('Active')} />
                  <Tab label={translate('InActive')} />
              <Tab label={translate('Draft')} />
          </Tabs>
          </div>
          <div className="qadpt-websearch-container">
    <TextField
      variant="outlined"
      placeholder={translate('Search Hotspot')}
      value={searchQuery}
      onChange={handleSearchQueryChange}
          onKeyDown={handleKeyDown}
          className='qadpt-websearch'
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              onClick={handleSearch}
              style={{ cursor: 'pointer' }}
            />
          </InputAdornment>
        ),
      }}
            />
            </div>

        <div
          className='qadpt-webgird qadpt-anngrd'
        >
      <DataGrid
        rows={Hotspots}
        columns={columns}
        getRowId={(row) => row.GuideId}
        getRowSpacing={getRowSpacing}
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rowCount={totalCount}
        pageSizeOptions={[15, 25, 50, 100]}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: 'Records Per Page',
          },
          noRowsLabel: getNoRowsLabel(),
        }}
        disableColumnMenu
              disableRowSelectionOnClick
              slots={{
                noRowsOverlay: NoRowsOverlay, // Using the 'slots' prop for NoRowsOverlay
              }}
      />
        </div>
        {openDialog && (
     <div className="qadpt-modal-overlay">       
  <div className="qadpt-usrconfirm-popup qadpt-danger">
    <div className="qadpt-popup-title">
    <div className="qadpt-icon">      
        <IconButton
							className="qadpt-svg">
								<i className='fal fa-trash-alt'></i>
								</IconButton>
                  </div>
                  </div>
      <div className="qadpt-popup-title">
        {translate('Delete Hotspot')}
      </div>

    <div className="qadpt-warning">
      {translate('The')} <span className="qadpt-delete-popup-bold">{GuidenametoDelete}</span> {translate('cannot be restored once it is deleted.')}
    </div>

    <div className="qadpt-buttons">
      <button
        onClick={() => setOpenDialog(false)}
        className="qadpt-cancel-button"
      >
        {translate('Cancel')}
      </button>
      <button
        onClick={handleDelete}
        className="qadpt-conform-button"
      >
        {translate('Delete')}
      </button>
    </div>
              </div>
              </div>
)}
    {showPopup ? (
         <> 
         <div
         style={{
           position: "fixed",
           top: 0,
           left: 0,
           width: "100vw",
           height: "100vh",
           backgroundColor: "rgba(0, 0, 0, 0.5)", 
           zIndex: 999, 
         }}
       />

       <div
         style={{
           position: "fixed",
           top: "50%",
           left: "50%",
           transform: "translate(-50%, -50%)", 
           zIndex: 1000, 
           backgroundColor: "white", 
          //  padding: "20px",
           borderRadius: "8px",
           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
         }}
       >
        <CreateNewPopup
    setTourslist={setHotspots}
    setShowPopup={setShowPopup}
    showPopup={showPopup}
    setTotalcount={setTotalCount}
    setActiveTab={setActiveTab}
    activeTab={activeTab}
    changeTab={changeTabToDraft}
    name={name}
   
                />
              </div>
        </>
        

  ) : (
    ""
    )}

{isCloneDialogOpen && cloneHotspotName && (
  <CloneInteractionDialog
    open={isCloneDialogOpen}
    handleClose={() => setIsCloneDialogOpen(false)}
    initialName={cloneHotspotName}
              onCloneSuccess={handleCloneSuccess}
              name={name}
  />
)}
     </div>
      </div>
  </Container>
  );
};

export default Hotspots;
